import ImgUlrich from "../img/ulrich_cropped.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function PersonTeaser() {
  const mobile: boolean = useMediaQuery("(max-width:600px)");
  const maxi: boolean = useMediaQuery("(min-width:1500px)");

  return (
    <div
      className="teaser-right"
      style={{ position: "relative", minHeight: "300px" }}
    >
      <div className="whiteback"></div>
      <div
        className="headline-right"
        style={{ maxWidth: "250px", width: "45%", zIndex: 5 }}
      >
        <h2>Wenn es sich biegt, ist es lustig. Wenn es bricht, nicht.</h2>
      </div>
      <img
        src={ImgUlrich}
        alt="Ulrich Klostermann"
        style={{
          height: "280px",
          position: "absolute",
          bottom: "0px",
          left: "0px",
          zIndex: -2,
        }}
      />
    </div>
  );
}
