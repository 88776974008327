import Grid from "@mui/material/Grid";

export default function Footer() {
  return (
    <div style={{ backgroundColor: "#1286c8", minHeight: "80px" }}>
      <Grid
        container
        padding={1}
        justifyContent={"space-evenly"}
        alignItems={"flex-end"}
        style={{ color: "#fff", fontSize: "0.8rem", minHeight: "100px" }}
      >
        <Grid item xs={12} sm={4} padding={1}>
          <strong>FRISCHE BRISE DIGITAL</strong>
          <br />
          Ulrich Klostermann
          <br />
          Father Bill Str 6
          <br />
          53175 Bonn
        </Grid>
        <Grid item xs={12} sm={6} padding={1}>
        <a href="https://www.linkedin.com/in/ulrich-klostermann-366139207" style={{color:"#fff"}}>on LinkedIn</a><br />
        <a href="mailto:uk@frische-brise-digital.de" style={{color:"#fff"}}>uk@frische-brise-digital.de</a>
          <br />
          Photos: Spurwing Agency, Scott Jackson, Milan de Clerq on unsplash.com
          <br />
          &copy; {new Date().getFullYear()} FRISCHE BRISE DIGITAL <br />
        </Grid>
      </Grid>
    </div>
  );
}
