import Grid from "@mui/material/Grid";

export default function Team() {
  return (
    <>
      <a id="Team" className="anchor" />
      <div
        className="contentDiv"
        style={{ backgroundColor: "#3383cb", color: "#fff" }}
      >
        <h1>Zur Person</h1>
        <Grid
          container
          justifyContent={"space-evenly"}
          className="contentBS"
          spacing={4}
        >
          <Grid item xs={12} sm={6} md={3}>
            <div className="shoutEntwicklungBS">Ulrich Klostermann</div>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                10 Jahre <strong>Programmierer</strong>
              </Grid>
              <Grid item xs={12}>
                10 Jahre <strong>Gründer & Unternehmer</strong> mit
                erfolgreichem Exit
              </Grid>
              <Grid item xs={12}>
                10 Jahre Head of Solution <strong>Management im Konzern</strong>
              </Grid>
            </Grid>
            <br />
            Ich habe in jeder Rolle Lehrgeld bezahlt und Erfolge gefeiert. Ich
            beobachte und helfe unseren Kunden mit einem{" "}
            <strong>ganzheitlichen Blick</strong> - aus Sicht von Entwicklern,
            Projekt- und Produktmanagern, Unternehmern und Führungskräften.
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div className="shoutEntwicklungBS">Meine Überzeugung</div>
            Ich bin der Überzeugung, dass diese{" "}
            <strong>ausgewogene Mischung</strong> aus technischem
            Hintergrundwissen, Unternehmertum, Verantwortung, Führungskraft und
            Organisationsverständnis, einen{" "}
            <strong>breiten und differenzierten Blick</strong> auf Unternehmen
            und Unternehmungen ermöglicht. <br />
            <br />
            Diesen Wert gebe ich in Form von Beratung und Schulung sowie durch{" "}
            <strong>Unterstützung</strong> bei der Planung und Implementierung
            von <strong>Lösungen</strong> weiter.
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <div className="shoutEntwicklungBS">Meine Partner</div>
            Nach Bedarf binde ich Beratungs- und IT-Partner für die Umsetzung
            der Projekte ein.
            </Grid>{" "}
        </Grid>
        <br />
        <br />
      </div>
    </>
  );
}
