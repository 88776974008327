import Grid from "@mui/material/Grid";

export default function Implementierung() {
  return (
    <>
      <a id="Implementierung" className="anchor" />
      <div
        className="contentDiv"
        style={{ backgroundColor: "#5b9bd5", color: "#fff" }}
      >
        <h1>Implementierung</h1>
        <Grid
          container
          justifyContent={"space-evenly"}
          className="contentBS"
          spacing={4}
        >
          <Grid item xs={12} sm={4} md={3}>
            <div className="shoutEntwicklungBS">Digitale Projekte</div>
            Frische Brise Digital unterstützt bei der Implementierung digitaler
            Projekte. Von der Erstellung von <strong>Epics</strong> (oder
            Lasten- oder Pflichtenheften) über die{" "}
            <strong>Programmierung</strong> bis zur{" "}
            <strong>Solution Readiness</strong> (oder Marktreife und
            -einführung) und dem <strong>Betrieb</strong> der Lösung.
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className="shoutEntwicklungBS">Was schafft Wert?</div>
            Frische Brise Digital kann dabei verschiedene Rollen einnehmen: Als
            Product-/Solution-<strong>Manager</strong>, Product{" "}
            <strong>Owner</strong> oder Projektmanager{" "}
            <strong>mit breiter Erfahrung</strong> in agilen und klassischen
            Arbeitsweisen und tiefem technischen <strong>Verständnis</strong>.
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <div className="shoutEntwicklungBS">Programmierung</div>
            Frische Brise Digital implementiert auf dem folgenden <strong>Tech Stack: React, React Native, Material
            UI, Node.js, AWS Serverless, Nx, MongoDB oder RDBMS</strong>.
          </Grid>{" "}
        </Grid>
        <br />
        <br />
      </div>
    </>
  );
}
