import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import ClickAwayListener from "@mui/base/ClickAwayListener";

import "./navigation.css";
import ImgLogo from "../img/fbd_logo.svg";

export default function Navigation() {
  const mobileNav: boolean = useMediaQuery("(max-width:900px)");
  const [navBg, setNavBg] = useState(false);
  const [showMobileMenuLayer, setShowMobileMenuLayer] = useState(false);

  useEffect(() => {
    // console.log(`mobileNav: ${mobileNav}`);
    if (!mobileNav) setShowMobileMenuLayer(false);
  }, [mobileNav]);

  window.onscroll = function () {
    if (document.documentElement.scrollTop > 50) {
      setNavBg(true);
      setShowMobileMenuLayer(false);
    } else setNavBg(false);
  };

  const handleClickAway = () => {
    setShowMobileMenuLayer(false);
  };

  function thisAnchor(navItem: string) {
    return (
      <a
        href={`#${navItem}`}
        onClick={() => {
          setShowMobileMenuLayer(false);
        }}
        className={`${navBg ? "navigation-textcolor-docked" : null}`}
        style={{
          color: "inherit",
          textDecoration: "inherit",
          whiteSpace: "nowrap",
        }}
      >
        {navItem}
      </a>
    );
  }
  function renderDesktopMenu() {
    return (
      <div className="nav-pos-desktop">
        <ul
          className={`navigation-ul ${
            navBg ? "navigation-textcolor-docked" : "navigation-textcolor"
          }`}
        >
          <li className="navigation-li">{thisAnchor("Beratung")}</li>
          <li className="navigation-li"> {thisAnchor("Implementierung")}</li>
          <li className="navigation-li"> {thisAnchor("Team")}</li>
        </ul>
      </div>
    );
  }

  function renderMobileMenuLayer() {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="nav-pos-mobile-layer">
          <div className="navigation-li">{thisAnchor("Beratung")}</div>
          <br />
          <div className="navigation-li"> {thisAnchor("Implementierung")}</div>
          <br />
          <div className="navigation-li"> {thisAnchor("Team")}</div>
        </div>
      </ClickAwayListener>
    );
  }

  function renderMobileMenu() {
    // console.log(`showMobileMenu: ${showMobileMenuLayer}`);
    return (
      <>
        <MenuIcon
          className={`nav-pos-mobile-hamburger  ${
            navBg ? "navigation-textcolor-docked" : "navigation-textcolor"
          }`}
          style={{ cursor: "pointer", fontSize: "2.5rem" }}
          onClick={() => {
            setShowMobileMenuLayer(!showMobileMenuLayer);
          }}
        />
        {showMobileMenuLayer ? renderMobileMenuLayer() : null}
      </>
    );
  }

  return (
    <div className={`navigation ${navBg ? "navigation-bg" : null}`}>
      <a href="#top">
        <img className="main-logo" src={ImgLogo} alt="agentur.actyvyst" />
      </a>
      {mobileNav ? renderMobileMenu() : renderDesktopMenu()}
    </div>
  );
}
