import useMediaQuery from "@mui/material/useMediaQuery";
import ImgBeratungSchulung from "../img/spurwing-agency-r6LceZ3ckpM-unsplash-big.jpg";
import ImgBeratungSchulungMobile from "../img/spurwing-agency-r6LceZ3ckpM-unsplash-mobile.jpg";

export default function BeratungTeaser() {
  const small: boolean = useMediaQuery("(max-width:700px)");
  const mobile: boolean = useMediaQuery("(max-width:900px)");
  let layoutFixMobile: object = {};

  if (mobile) {
    layoutFixMobile = { display: "flex", alignItems: "flex-start" };
  } else {
    layoutFixMobile = {};
  }

  return (
    <div
      className="teaser-right photoback"
      style={{
        height: 600,
        backgroundImage: `url(${
          small ? ImgBeratungSchulungMobile : ImgBeratungSchulung
        })`,
        ...layoutFixMobile,
      }}
    >
      <div
        className="headline-right"
        style={{ width: "90%", maxWidth: "450px", marginTop: "10px" }}
      >
        <h1>Gerade soviel Digitalisierung und Agilität wie es Wert stiftet!</h1>
      </div>
      <div
        className="blackback"
        style={{ background: "rgba(0, 0, 0, 0.1)" }}
      ></div>
    </div>
  );
}
