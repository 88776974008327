import useMediaQuery from "@mui/material/useMediaQuery";
import ImgImplementierung from "../img/scott-jackson-C6SvKG2uDHU-unsplash-big.jpg";
import ImgImplementierungMobile from "../img/scott-jackson-C6SvKG2uDHU-unsplash-mobile.jpg";

export default function ImplementierungTeaser() {
  const small: boolean = useMediaQuery("(max-width:700px)");

  return (
    <div
      className="teaser-right photoback"
      style={{
        height: 750,
        backgroundImage: `url(${
          small ? ImgImplementierungMobile : ImgImplementierung
        })`,
        alignItems: "flex-start",
      }}
    >
      <div className="blackback"></div>
      <div
        className="headline-right"
        style={{ maxWidth: 400, marginTop: "120px" }}
      >
        <h1>Lösungen, die zum Problem passen!</h1>
      </div>
    </div>
  );
}
