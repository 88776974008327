import ImgTopic from "../img/milan-de-clercq-Y_N-UJmH9Ok-unsplash-big.jpg";
import ImgTopicMobile from "../img/milan-de-clercq-Y_N-UJmH9Ok-unsplash-mobile.jpg";
import ImgTopicMaxi from "../img/milan-de-clercq-Y_N-UJmH9Ok-unsplash-wide.jpg";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function TeamTeaser() {
  const mobile: boolean = useMediaQuery("(max-width:600px)");
  const big: boolean = useMediaQuery("(max-width:900px)");
  const maxi: boolean = useMediaQuery("(min-width:1500px)");

  let backgroundFix: any = {};
  if (big && !mobile) {
    backgroundFix = { backgroundPosition: "left" };
  } else backgroundFix = null;

  let imgTeaser: string = ImgTopic;
  if (mobile) imgTeaser = ImgTopicMobile;
  else if (maxi) imgTeaser = ImgTopicMaxi;

  return (
    <div
      className="teaser-right photoback"
      style={{
        height: 700,
        backgroundImage: `url(${imgTeaser})`,
        alignItems: "flex-start",
        ...backgroundFix,
      }}
    >
      <div className="blackback"></div>
      <div className="headline-right" style={{ maxWidth: "330px" }}>
        <h1>
          <br />
          Weil die Arbeit mit uns eine Form von Schwere&shy;losigkeit ist!
        </h1>
      </div>
    </div>
  );
}
