// import styled from 'styled-components';

import "./App.css";
import "./pages/pages.scss";

import Navigation from "./components/navigation";
import BeratungTeaser from "./pages/beratung-teaser";
import Beratung from "./pages/beratung";
import ImplementierungTeaser from "./pages/implementierung-teaser";
import Implementierung from "./pages/implementierung";
import TeamTeaser from "./pages/team-teaser";
import Team from "./pages/team";
import PersonTeaser from "./pages/person-teaser";
import Footer from "./components/footer";

export default function App() {
  return (
    <>
      <Navigation />
      <ImplementierungTeaser />
      <Implementierung />
      <BeratungTeaser />
      <Beratung />
      <TeamTeaser />
      <Team />
      <PersonTeaser />
      <Footer />
    </>
  );
}
