import Grid from "@mui/material/Grid";

export default function Beratung() {
  return (
    <>
      <a id="Beratung" className="anchor" />
      <div
        className="contentDiv"
        style={{ backgroundColor: "#3383CB", color: "#fff" }}
      >
        <h1>Beratung</h1>
        <Grid
          container
          justifyContent={"space-between"}
          className="contentBS"
          spacing={4}
        >
          <Grid
            item
            xs={12}
            md={7}
            container
            spacing={3}
            justifyContent={"space-between"}
          >
            <Grid item xs={12} sm={6} md={4}>
              <div className="shoutEntwicklungBS">Pragmatisch</div>
              Frische Brise Digital hilft, einen{" "}
              <strong>pragmatisch-undogmatisch</strong>en Weg der digitalen
              Transformation zu finden und in kleinen Schritten umzusetzen,
              deren <strong>Erfolg messbar und spürbar</strong> ist.
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className="shoutEntwicklungBS">Maßvoll</div>
              Wir finden <strong>das richtige Maß an Veränderung</strong> -
              nicht um der Veränderung willen, sondern für größeren{" "}
              <strong>Erfolg und mehr Zufriedenheit</strong>. Weniger ist mehr,
              wenn man das Richtige tut.
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="shoutEntwicklungBS">Zielgerichtet</div>
              Frische Brise Digital hilft, Ihr Problem oder{" "}
              <strong>Ziel genau</strong> zu{" "}
              <strong>verstehen, bevor es an die Lösung geht</strong>. Ob Sie
              sich gerade auf den Weg machen, schon losgelaufen sind und
              Widerstand verspüren, oder der Karren gegen die Wand fährt.
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            container
            spacing={2}
            justifyContent={"flex-start"}
          >
            <Grid item xs={12} md={12} className="shoutEntwicklungBS">
              Im Werkzeugkasten
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              Analyse und Problem / Zielbeschreibung
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              Value Proposition Design & Business Canvas Design
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              Rahmenparameter zur Lösungsfindung
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              Lösungsfindung und Machbarkeits-Analyse
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              Objectives and Key Results
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              Stakeholder-Management
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              Agile Arbeitsweisen
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              Solution Readiness
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              Messbarkeit & Erfolgskontrolle
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={5}>
              Kommunikation
            </Grid>
          </Grid>
        </Grid>
        <br />
        <br />
      </div>
    </>
  );
}
